import { MagicUseCase } from '~/use-cases/magicUseCase';

export class SetSearchUnitsUseCase extends MagicUseCase {
  protected async runLogic(search: string) {
    const unitsListing = this.getState().user.unitsListing || {};
    this.getState().user.unitsListing = {
      ...unitsListing,
      search,
      currentPage: 0,
    };
  }
}
