import { PotentialUnitsStep } from '~/pages/rental-application/steps/PotentialUnitsStep';

const PotentialUnits = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <PotentialUnitsStep />
    </div>
  );
};

export default PotentialUnits;
