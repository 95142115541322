import { useParams, useSearchParams } from '@solidjs/router';
import { Component, createMemo, createSignal, onMount, Show } from 'solid-js';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Checkbox, IconSearch, InfiniteScroll } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { GetUnitsListingUseCase } from '~/use-cases/rental-applications/application-steps/getUnitsListingUseCase';
import { SetSearchUnitsUseCase } from '~/use-cases/rental-applications/application-steps/setSearchUnitsUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { GoToPrevStepUseCase } from '~/use-cases/rental-applications/goToPrevStepUseCase';
import { debounce } from '~/utils/actionBehaviors';
import { UnitsGrid } from '../screening/components/ImageGrid';

export const PotentialUnitsStep: Component = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams<{
    search?: string;
  }>();
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);

  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: getUnits, isLoading: isLoadingUnits } = useUseCase(GetUnitsListingUseCase);
  const { execute: setUnitsSearch } = useUseCase(SetSearchUnitsUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: goToPrevStep } = useUseCase(GoToPrevStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);

  const initialLoading = createMemo(() => {
    return !presentableRentalApplication()?.unitsOfInterest?.currentPage && isLoadingUnits();
  });

  const fetchUnits = async () => {
    await getUnits({ id: params.id, password: params.password });
  };

  const debouncedSearch = debounce(() => {
    fetchUnits();
  }, 500);

  onMount(() => {
    if (presentableRentalApplication()?.unitsListingSearch) {
      setSearchParams({ search: presentableRentalApplication()?.unitsListingSearch });
    }

    if (searchParams.search) {
      setUnitsSearch(searchParams.search);
    }

    if (!presentableRentalApplication()?.unitsOfInterest?.currentPage) {
      fetchUnits();
    }
  });

  const onSelectionChange = (selectedValues: string[]) => {
    updateRentalApplication({
      interestedUnitIds: selectedValues,
    });
  };

  const toggleOpenToAllUnits = () => {
    if (presentableRentalApplication()?.isOpenToAllUnits) return;
    updateRentalApplication({
      interestedUnitIds: [],
    });
  };

  const onPrev = async () => {
    await goToPrevStep({ currentStep: RentalApplicationSteps.POTENTIAL_UNITS, credentials: { id: params.id, password: params.password } });
  };

  const onNext = async () => {
    await validateRentalApplication();
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasApplicantInfoError) {
      return;
    }
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.POTENTIAL_UNITS,
      credentials: { id: params.id, password: params.password },
    });
    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  const onSearchInput = (value: string) => {
    setUnitsSearch(value);
    setSearchParams({ search: value });
    debouncedSearch();
  };

  return (
    <Show
      when={!isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div
        class="bg-white p-5"
        style={{
          '--bg': '#F6F6FC',
        }}>
        <div class="mb-6">
          <h2 class="mb-3 text-sm text-text-level01">Interested unit</h2>
          <div class="flex h-12 items-center divide-x rounded-lg border border-input-border px-4">
            <input
              type="text"
              placeholder="Search unit..."
              value={searchParams.search ?? ''}
              class="mr-4 flex-1 bg-transparent placeholder:text-sm placeholder:text-auxiliary-text focus:outline-none"
              onInput={(e) => onSearchInput(e.target.value)}
            />
            <div class="pl-4">
              <IconSearch class="pointer-events-none size-5 cursor-pointer text-[#CBD4EB]" />
            </div>
          </div>
        </div>
        <div class="border-b border-input-border pb-3">
          <h2 class="text-sm text-text-level01">All units</h2>
        </div>
        <div
          class="my-3 flex h-12 cursor-pointer items-center divide-x rounded-lg bg-[var(--bg)] px-4"
          onClick={() => toggleOpenToAllUnits()}>
          <Checkbox checkBoxClass="pr-4" class="rounded-full" checkedValue={presentableRentalApplication()?.isOpenToAllUnits} />
          <span class="pl-4 text-sm font-medium">Open to all units</span>
        </div>
        <Show when={!initialLoading()} fallback={<UnitsGrid loading />}>
          <InfiniteScroll
            onReachEnd={() => {
              fetchUnits();
            }}
            ended={presentableRentalApplication()?.unitsOfInterest?.ended}
            rootElement={document.documentElement}
            as="div"
            class="scrollbar flex h-section3 flex-col "
            loaderClass="my-0 mt-5">
            <UnitsGrid units={presentableRentalApplication()?.unitsOfInterest?.items || []} onSelectionChange={onSelectionChange} />
          </InfiniteScroll>
        </Show>
      </div>
      <StepFooter onNext={onNext} onPrevious={onPrev} />
    </Show>
  );
};
