export default function clickOutside(el: HTMLElement | null, onClickOutside: () => void) {
  const onClick = (e: MouseEvent) => {
    if (el && !el.contains(e.target as Node)) {
      onClickOutside();
    }
  };

  document.body.addEventListener('click', onClick);

  return () => document.body.removeEventListener('click', onClick);
}

export function debounce<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function debounced(this: D, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
